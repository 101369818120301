import request from '@/utils/request'
import urlConfig from "../utils/urlConfig";

/**
 *  关键字搜索
 * @param keyword关键字
 */
export function searchKey(keyword) {
    return request({
        url: urlConfig.searchUrl + '/search/zrzydy?keyworld='+keyword,
        method: 'get',
        data: {}
    })
}
