<template>
    <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="2008">
        </el-pagination>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                currentPage: 2
            }
        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            }
        }
    }
</script>
<style lang="scss">
    .pagination{
        text-align:center;
        margin:20px 0;
        .el-pagination{
            font-weight:500;
        }
    }
</style>