<template>
  <div class="search center">

    <p class="result-num"><span>为您找到{{ amount }}条查询结果</span></p>
    <p class="result-text"><span>搜索结果</span></p>
    <el-row :gutter="20" class="filter-row">
      <el-col v-for="(sel, index) in filterData" :key="index" :span="3">
        <div :class="{'active': index == currTab}" @click="handleFilter(index, sel.type)">{{ sel.text }}</div>
      </el-col>
    </el-row>
    <!-- 全部 -->
    <div v-if="isShowNotFound">
      <el-row>
        <el-col v-for="(list, index) in listData" :key="index">
          <el-row :gutter="20" class="list-row">
            <!--                    <el-col :span="6" class="thum-col">-->
            <!--                        <el-image :src="list.iconPath">-->
            <!--                        </el-image>-->
            <!--                    </el-col>-->
            <el-col :span="24" class="cont-col">
              <p class="title-p" @click="toDetail(list)">
                <span class="title" v-html="list.title"></span>
                <span :class="list.type" class="tag">{{ list.type | defaultValue }}</span>
              </p>
              <p v-if="list.dq" class="type-p">
                <!--                            <span class="type">{{list.info.type}}</span>-->
                <span class="location">{{ list.dq }}</span>
                <span class="area">{{ list.mj }}公顷</span>
                <span class="date">{{ list.time }}</span>
              </p>
              <p class="text-p" v-html="list.content">
              </p>
              <span class="post-date">{{ list.time }}</span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="pagination">
        <el-pagination
            :current-page="current"
            :page-size="size"
            :total="amount"
            layout="prev, pager, next"
            @current-change="currentPage"
            @next-click="nextPage"
            @prev-click="prevPage"></el-pagination>
      </div>
    </div>
    <not-found v-else></not-found>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination'
import {searchKey} from "../../api/searchService"
import notFound from '@/components/notFound'

export default {
  components: {
    pagination,
    notFound
  },
  filters: {
    defaultValue(val) {
      if (val == 'Info') {
        return '单元信息'
      } else if (val == 'News') {
        return '新闻报道'
      } else if (val == 'other') {
        return '科普知识'
      } else if (val == 'policy') {
        return '法律法规'
      }else if (val == 'discuss') {
        return '重要论述'
      } else if (val == 'notice') {
        return '通告公告'
      } else if (val == 'experience') {
        return '地方经验'
      }
      return val
    }
  },
  watch: {
    $route: {
      handler() {
        this.key = this.$route.params.key;
        this.handleFilter(0, 'all')
        this.clickSearchKey();
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    }
  },
  data() {
    return {
      key: "",
      filterData: [
        {
          type: 'all',
          text: '全部',
          isActive: false
        },
        {
          type: 'Info',
          text: '单元信息',
          isActive: false
        },
        {
          type: 'News',
          text: '新闻报道',
          isActive: false
        },
        {
          type: 'other',
          text: '科普知识',
          isActive: false
        },
        {
          type: 'policy',
          text: '法律法规',
          isActive: false
        },
        {
          type: 'discuss',
          text: '重要论述',
          isActive: false
        },
        {
          type: 'notice',
          text: '通告公告',
          isActive: false
        },
        {
          type: 'experience',
          text: '地方经验',
          isActive: false
        },
      ],
      rawData: [], // 结果集合
      lxData: [], // 类型结合
      listData: [], // 页面显示结果处理集合
      currTab: 0, // 当前标签
      amount: 0,  // 总数
      size: 10,
      current: 1,
      isShowNotFound: true,
    }
  },
  methods: {
    handleFilter(index, type) {
      this.currTab = index
      if (type !== 'all') {
        this.lxData = this.rawData.filter((item) => {
          return item.type === type
        })
      } else {
        this.lxData = this.rawData
      }
      this.amount = this.lxData.length
      this.isShowNotFound = this.amount > 0;
      this.setListData(0, this.size)
    },
    /**
     * 跳转
     * @param list
     */
    toDetail(list) {
      let name;
      if (list.type === 'Info') {
        name = 'infoDetail'
        this.$router.push({
          name: name,
          query: {
            zrzyDyId: list.id
          }
        })
        return
      } else if (list.type === 'policy') {
        name = 'policyDetail'
      } else {
        name = 'newsDetail'
      }
      this.$router.push({
        name: name,
        query: {
          id: list.id
        }
      })

    },
    /**
     * 搜索
     */
    clickSearchKey() {
      this.key = this.$route.params.key;
      searchKey(this.key).then(res => {
        if (res.code === "0") {
          this.rawData = res.data; // 查询结果数据
          this.lxData = res.data; // 分类结果数据
          this.amount = res.data.length // 统计
          this.isShowNotFound = this.amount > 0;

          this.setListData(0, this.size)

        }
      })
    },
    /**
     * 下一页
     */
    nextPage() {
      this.setListData(this.current * this.size, this.current * this.size + this.size)
    },
    /**
     * 上一页
     */
    prevPage() {
      this.setListData((this.current - 1) * this.size, (this.current - 1) * this.size + this.size)
    },
    /**
     * 点击页数
     * @param event
     */
    currentPage(event) {
      console.log(event)
      this.setListData((event - 1) * this.size, (event - 1) * this.size + this.size)
    },
    /**
     * 设置显示集合
     * @param start
     * @param end
     */
    setListData(start, end) {
      this.listData = JSON.parse(JSON.stringify(this.lxData)).splice(start, end)
    }
  },
  created() {
    this.clickSearchKey();

  }
}
</script>
<style lang="scss">
.pagination {
  text-align: center;
  margin: 20px 0;

  .el-pagination {
    font-weight: 500;
  }
}

.search {
  .result-num {
    font-size: 14px;
    color: #666;
    margin-top: 15px;
  }

  .result-text {
    margin: 30px 0 20px;
    font-size: 24px;
    color: #000;
  }

  .filter-row {
    .el-col {
      div {
        color: #333;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        background-color: #ECF1F3;
      }

      div.active, div:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .list-row {
    padding: 20px 0;
    border-bottom: 1px solid #E6E6E6;

    .thum-col, .cont-col {
      height: 160px;
    }

    .thum-col {
      img {
        width: 100%;
        max-height: 160px;
      }
    }

    .cont-col {
      position: relative;

      .title-p {
        overflow: hidden;
        padding: 11px 0;
        margin-bottom: 5px;
        cursor: pointer;

        span {

          line-height: 32px;
        }

        .title {
          float: left;
          font-size: 24px;
          margin-right: 20px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .tag {
          font-size: 16px;
          padding: 0 13px;
        }

        .Info {
          color: #11C36A;
          background-color: #DBFFE8;
        }

        .News {
          color: #0F87FF;
          background-color: #D6EBFF;
        }

        .policy {
          color: #FF9E13;
          background-color: #FFF0DB;
        }

        .other {
          color: #ff00ff;
          background-color: #FFCCFF;
        }
      }

      .type-p {
        overflow: hidden;
        margin-bottom: 5px;

        span {
          float: left;
          color: #666;
          padding-left: 22px;
          line-height: 20px;
          font-size: 14px;
          margin-right: 20px;
          background: left center no-repeat;
        }

        .type {
          background-image: url(../../assets/images/icon_type.png)
        }

        .location {
          background-image: url(../../assets/images/icon_location.png)
        }

        .area {
          background-image: url(../../assets/images/icon_area.png)
        }

        .date {
          background-image: url(../../assets/images/icon_date.png)
        }
      }

      .text-p {
        height: 45px;
        font-size: 14px;
        color: #666;
        line-height: 22px;
        margin-bottom: 9px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .post-date {
        font-size: 14px;
        color: #999;
        position: absolute;
        left: 10px;
        bottom: 10px;
      }
    }
  }
}
</style>
